$menu-width: 224px;
$header-height: 54px;
$footer-height: 50px;
$footer-gap: 50px;
$padding-top: 39px;
$margin-top-md: 39px;
$margin-top: 63px;
$page-height: $header-height + $footer-height + $footer-gap;

.root {
  font-size: 13px;
}

div.container-xl {
  max-width: 1160px;
  padding: 0px 15px;
  margin: 0px -15px;
}

.app-container {
  background: $app-container-background;
}

.app-left-panel {
  position: fixed;
  width: $menu-width;
  z-index: 10;
  height: 100%;
  top: $header-height;
  display: block;

  &.show {
    display: block;
    position: fixed;
    top: $header-height;
  }
}
.app-header-panel {
  position: absolute;
  z-index: 1000 !important;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  .page-progress {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 8px;
  }

  &.pinned {
    position: fixed;
    z-index: 9;

    .page-progress {
      top: 52px;
      left: 0;
      width: 100%;
    }

    &.scrolled {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    }
  }

  .app-header {
    height: $header-height;
    background: #0e0e0eeb;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  }
  .fa-bars {
    cursor: pointer;
    .fa-sm {
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }
  }
  .fa-bars {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  .app-hamburger-container {
    margin-right: 15px;
    height: $header-height;
    width: $header-height;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    float: left;
    background: none;
    color: $white;
    width: 62px;
    cursor: pointer;
  }

  .app-breadcrumb {
    float: left;
    padding: 17px 0px;
    font-weight: 400;
    font-size: 14px;
  }

  .app-user {
    float: right;
    display: inline-flex;
    .app-user-icon {
      font-size: 20px;
      padding: 9px 24px;
      color: $white;
      cursor: pointer;

      .app-user-card {
        text-align: right;
        display: inline-block;
        min-width: 250px;
        position: fixed;
        top: $header-height;
        right: 24px;
        z-index: 10;
        font-size: 1rem;
        opacity: 1;
        visibility: visible;
        font-size: 15px;

        .card-arrow {
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent $bs-dark transparent;
          border-style: solid;
          position: absolute;
          top: -8px;
          right: 19px;
          z-index: 1;
        }
        a {
          color: $bs-dark;
          font-size: 15px;
        }
      }
    }
    button.link-help-btn.btn.btn-link {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .app-header-thumb {
    position: fixed;
    top: 10px;
    right: 5px;
    padding: 2px 5px;
    border: 1px solid transparent;
    background-color: $white;

    &:hover {
      border: 1px solid $lightGrey;
    }
  }
}

div.bread-crumb {
  display: inline;
  color: $white;

  div.bread-crumb-level {
    display: inline;

    span.bread-crumb-seperator {
      padding: 0 10px;
    }
  }
}

.app-right-panel {
  margin-left: 0px;
  padding-top: $padding-top;

  .pull-right {
    float: right;
  }
  .customMarginTop {
    margin-top: $margin-top-md;
  }

  .app-content-area {
    min-height: calc(100vh - #{$page-height});
    color: $color-text;
  }
  .loader-container {
    overflow: auto;
  }
}

.app-footer {
  padding: 10px 13px;
  height: $footer-height;
  text-align: center;
  margin-top: $margin-top;
  color: $color-text;
  background: $color-footer;
}

div.toast-container {
  position: fixed;
  right: 0px;
  top: $header-height;
  padding: 10px;
  backdrop-filter: blur(3px);
  border-radius: 5px;
}

div.responsive-hidden {
  margin-top: -19px;
  color: white;
}
.container-fluid {
  margin-left: 0;
  padding-top: 15px;
}

// Small devices ( phones, 375px and up to larger mobiles)
//Here the menu will display full - with an faded background
//as you click the fadded background the FULL menu Disappear
@media (min-width: 345px) and (max-width: 768px) {
  .app-header-panel {
    z-index: 205;
    .app-header {
      position: fixed;
      width: 100%;
    }
  }
  //Hidden Start
  .responsiveOverlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 1s, opacity 1s;

    &.show {
      bottom: 0px;
      visibility: visible;
      opacity: 1;
      left: 0px;
      position: fixed;
      right: 0px;
      top: 0px;
      z-index: 195;
      background: rgba(9, 30, 66, 0.54);
      transition: visibility 0s linear 0s, opacity 1s;
    }
  }

  div.loader-container.page-loader {
    > div.loader-overlay {
      left: $menu-width;
    }
  }
  .app-left-panel {
    margin-left: -224px;
    transition: margin 1s;

    div.responsive-hidden {
      display: inline-block;
      visibility: visible;
    }
    //Hidden end
    //Full Start
    &.show {
      z-index: 205;
      margin-left: 0px;
      -webkit-transition: margin 1s; /* For Safari 3.1 to 6.0 */
      transition: margin 1s;
      //this is here, as its relative to the app-left-panel
      .sidebar img {
        width: 88%;
        margin-top: 10px;
      }
      div.responsive-hidden {
        display: contents;
      }
      //this is here as its relative to the app-left-panel
      .sidebar .sidebar-content ul li .menu-item .fa {
        font-size: 14px;
      }
    }
  }
  .app-right-panel {
    padding-left: 0px;

    .customMarginTop {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  .app-breadcrumb {
    position: relative;
    left: -20px;
    bottom: 0px;
    font-size: 12px !important;
  }
}
//Full end

//media for Tablet up to tablet and laptop
@media (min-width: 769px) and (max-width: 1360px) {
  // This is reversed for in tablet mode.
  //While the class is mini-nav, it will default to the mini-nav, but not have the class
  //therefore adding mini-nav, will make it show.
  //Collapsed Start
  div.mini-nav .app-right-panel {
    padding-left: 224px;
    transition: all 1000ms ease;
  }
  div.mini-nav .app-left-panel {
    display: block;
    top: $header-height;
    width: 224px;
    z-index: 1;
    // this is here, as its relative to the app-left-panel
    .sidebar img {
      width: 85%;
      display: none;
    }
    .sidebar-header {
      margin-top: 0px;
      display: block !important;
    }
    div.responsive-hidden {
      display: inline-block;
      visibility: visible;
    }
    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 14px;
    }
    .sidebar .sidebar-content ul li .menu-item .fa .app-container.mini-nav {
      color: $white;
      position: relative;
      left: 95%;
    }
  }
  //Collapsed end

  // Display Full  start
  div.app-right-panel {
    padding-left: 62px;

    .customMarginTop {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  .app-left-panel {
    &.show {
      .sidebar {
        .sidebar-header {
          display: none;
        }
      }
    }
    width: 72px;
    -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
    transition: width 1s;
    z-index: 1;

    //this is here as its relative to the app-left-panel
    .sidebar img {
      width: 0px;
      padding: 0px;
    }
    div.responsive-hidden {
      visibility: hidden;
    }
    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 18px;
    }
  }
  .app-breadcrumb {
    position: relative;
    left: -20px;
    bottom: 0px;
    font-size: 12px !important;
  }
  .app-header {
    position: fixed;
    width: 100%;
  }
}
.cursorPointer {
  cursor: pointer;
}

// Display Full End

//media for Laptop and secondary screens or larger
// Smaller than 1360px - menu will be icons with option to make bigger on burger icon
@media (min-width: 1361px) {
  //Collapsed Start
  div.loader-container.page-loader {
    > div.loader-overlay {
      left: $menu-width;
    }
  }
  div.mini-nav .app-right-panel {
    padding-left: 62px;
    transition: all 0.5ms ease;
  }
  .app-left-panel {
    width: 70px;
    -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
    transition: width 0.5ms;
    z-index: 0;

    div.responsive-hidden {
      visibility: hidden;
      margin-top: -19px;
    }
    //this is here, as its relative to the app-left-panel
    .sidebar img {
      width: 0%;
      margin-top: -15px;
    }
    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 15px;
    }
    div > div:nth-child(1) > ul > li:nth-child(5) > div > a {
      height: 62px;
    }
    //Collapsed End

    //Full Start
    &.show {
      width: 224px;
      z-index: 0;
      //this is here, as its relative to the app-left-panel
      .sidebar img {
        width: 85%;
        margin-top: 15px;
      }
      div.responsive-hidden {
        display: inline-block;
        visibility: visible;
      }
      //this is here as its relative to the app-left-panel
      .sidebar .sidebar-content ul li .menu-item .fa {
        font-size: 14px;
      }
      ul.menu-level-1 div.menu-item {
        height: 54px;
        font-size: 15px;
      }
    }
  }
  .app-right-panel {
    padding-left: 224px;
  }
  .fa.fa-search {
    color: $white;
  }
  td.neo-grid-button-column > button.btn.btn-danger {
    min-width: 40px;
    width: auto;
  }
  //Tab
}

//Full End
@include media-breakpoint-down(xl) {
  .app-header-thumb {
    display: none;
  }
  div.responsive-hidden {
    opacity: 1;
    transition: ease-in-out visibility 1s linear 1s, opacity 1s;
  }
  .app-right-panel {
    transition: all 1000ms ease;
  }
  td.neo-grid-button-column > button.btn.btn-danger {
    min-width: 40px;
    width: auto;
  }
}

.toast-container {
  z-index: 50000 !important;
}
