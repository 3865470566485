div.box {
    background-color: $bs-dark;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

div.tabs-demo {
    .tab-content > .tab-pane {
        color: $silver;
    }
}

div.loading-target {
    border: 1px solid $grey;
    padding: 30px;
}

.pager-demo-2 {
    div.row > div {
        margin: 5px 0;

        div {
            border: 1px solid $lightGrey;
            padding: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}

div.model-rules-demo {
    display: flex;
    align-items: baseline;

    .alert {
        margin: 0 0 0 10px;
        padding: 7px 12px;
        font-size: 0.95rem;
    }
}

div.log {
    font-family: monospace;
    line-height: 1;
    min-height: 150px;
}