@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4daf50 !important;
    border-color: #4daf50 !important;
}

.page-link:hover {
    z-index: 2;
    color: #0b314f;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.input-group-text {
    background-color: #4daf50 !important;
    color:#fff;
}

.input-group-text > i {
    color:#fff;
}

.filter-input-group {
    width:auto;
}

.editable-column {
    color: #4daf50 !important;
}

.modal-footer >*> .btn-success {
    background-color: #cb5c2d !important;
}

// .column-sortable{
//     color:  #4daf50 !important;
// }
