body {
  margin: 0;
  // padding: 0.5rem;
  font-family: sans-serif;
  background-color: #f0f3f5;
}

// Drag and Drop object css
.item {
  font-family: sans-serif;
  border-radius: 25px;
  height: auto;
  width: 100%;
  margin-bottom: 2px;
  border-width: 2px !important;
  border: solid ;
  border-color: #4daf50;
  margin-right: 40px;
  margin-bottom: 15px;
  padding: 5px;
}

.item:hover{
  background-color: #c7ffd1;
  cursor: grab;
}

.description{
  margin-top: 10%;
  text-align: center;
}

.description-box{
 border:solid;
 border-width: 2px;
 max-height: auto;
 border-color: #4daf50;
}

 .item:hover .title {
  cursor: grab;
}

.item .title {
  font-weight:200;
  font-size: 130%;
  color: #4daf50;
  margin-left: 5px;
}

.item .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .small {
  font-size: 66%;
  text-align: right;
  white-space: nowrap;
}

.dragged .item {
  opacity: 0.1;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 0.2rem 2rem #666666;
}

.dndOverFlow {
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: auto;
}

.purpose{
margin-left: 5px;
font-size: 110%;
}

.order{
  margin-left: 5px;
  font-size: 90%;
}

// RO Drag and Drop object css
.ROitem {
  font-family: sans-serif;
  border-radius: 25px;
  height: auto;
  width: 100%;
  margin-bottom: 2px;
  border-width: 2px !important;
  border: solid ;
  border-color: #145B92;
  margin-right: 40px;
  margin-bottom: 15px;
  padding: 5px;
}

.ROdescription{
  margin-top: 10%;
  text-align: center;
}

.ROdescription-box{
 border:solid;
 border-width: 2px;
 max-height: auto;
 border-color: #145B92;
}

.ROtitle {
  cursor: not-allowed;
}

.ROitem .ROtitle {
  font-weight:200;
  font-size: 130%;
  color: #145B92;
  margin-left: 5px;
}

.ROitem .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.ROitem .small {
  font-size: 66%;
  text-align: right;
  white-space: nowrap;
}

.dragged .ROitem {
  opacity: 0.1;
}

.floating .ROitem {
  background-color: #ffffff;
  box-shadow: 0 0.2rem 2rem #666666;
}

.dndOverFlow {
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: auto;
}

.ROpurpose{
margin-left: 5px;
font-size: 110%;
}

.ROorder{
  margin-left: 5px;
  font-size: 90%;
}

.ROitem:hover{
  cursor: not-allowed;
}

