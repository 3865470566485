@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
.modal {
  width: 100%;
  max-height: 1000px !important;
  overflow-y: hidden !important;
}

.modal .modal-body .loader-container  {
  overflow-y:auto !important;
  max-height: 800px;
}