.card-title-white{
  color: "#FFFFFF";
  margin-Top: "0px";
  min-Height: "auto";
  font-Weight: "300";
  font-Family: "Roboto";
  margin-Bottom: "3px";
  text-Decoration: "none";
}
.card-title-white-small{
  color: #FFFFFF;
  margin-Top: 0px;
  min-Height: auto;
  font-Weight: 300;
  font-Family: Roboto;
  margin-Bottom: 3px;
  text-Decoration: none;
  font-size: 18px;
}
.card-category-white {
  color: "rgba(255,255,255,.62)";
  margin: "0";
  font-Size: "14px";
  margin-Top: "0";
  margin-Bottom: "0",
}

.card-category {
  color: "#999";
  margin: "0";
  font-Size: "14px";
  margin-Top: "0";
  padding-Top: "10px";
  margin-Bottom: "0";
}

.card-title {
  color: "#3C4858";
  margin-Top: "0px";
  min-Height: "auto";
  font-Weight: "300";
  font-Family: "'Roboto', 'Helvetica', 'Arial', sans-serif";
  margin-Bottom: "3px";
  text-Decoration: "none";
}