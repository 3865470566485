//General colors
$white: #ffffff;
$tabBackground: #f1f1f1;
$offWhite: #f7f7f7;
$backgroundGrey: #fafafa;
$offGrey: #ecefef;
$veryLightGreyBlue: #ecf0f4;
$lightGrey: #eeeeee;
$lightGrey: #dddddd;
$searchGrey: #ced4da;
$TabBackground: #f1f1f1;
$greyA: #aaaaaa;
$grey: #f0f3f5;
$skyBlue: #cfe6ee;
$headingGrey: #949292;
$mediumBlue: #2b6094;
$darkBlue: #25408f;
$darkerBlue: #182969;
$royalBlue: #0c2666;
$greyDarkBlue: #202c36;
$grayBlue: #2a3b4a;
$turquoise: #04c4d1;
$offBlue: #4f95bb;
$hoverBlue: #889fb2;
$lighterBlue: #00bcff;
$waterBlue: #4dc7e4;
$opacityBlue: #c4d9e5;
$green: #109110;
$lightGreen: #58a058;
$pink: #ff007a;
$darkPink: #ff007a;
$red: #d90000;
$orange: #e76565;
$lightOrange: #f6871f;
$warningOrange: #f8881f;
$lighterOrange: #f19d4e;
$hoverOrange: #fff5f0;
$silver: #a7b1c2;
$darkSilver: #7a7a7a;
$greyBlue: #607d8b;
$brown: #58595b;
$darkBrown: #555555;
$coal: #444444;
$darkCoal: #333333;
$lightBlack: #222222;
$darkBlack: #111111;
$black: #000000;
$lightPurple: #9579ac;
$concrete: #dee2e6;

$alert-bg-level: -9.5;
$alert-border-level:-8;
$alert-color-level: 7;

$form-border: #ced4da;
$form-focused-border: #419de5;
$form-widget-color: #e9ecef;
$form-focused-border: #80bdff;
$form-focused-shadow-color: rgba(0, 123, 255, 0.25);
$form-focused-shadow: 0 0 0 0.2rem $form-focused-shadow-color;

// Validation colours
$validation-color-error: rgb(119, 9, 9);
$validation-color-warning: #d66500;
$validation-color-info: #117a8b;

$validation-border-error: $validation-color-error;
$validation-shadow-error-color: rgba(220, 53, 69, 0.25);
$validation-shadow-error: 0 0 0 0.2rem $validation-shadow-error-color;

$validation-border-warning: $validation-color-warning;
$validation-shadow-warning-color: rgba(214, 101, 0, 0.25);
$validation-shadow-warning: 0 0 0 0.2rem $validation-shadow-warning-color;

$validation-border-info: $validation-color-info;
$validation-shadow-info: 0 0 0 0.2rem rgba(17, 122, 139, 0.25);

// Application Layout/Sidebar Colours
$color-background: #f5f5f5;
$color-hover: #f6f6f6;
$color-sidebar: #2f353a;
$color-footer: #f0f3f5;
$color-text: #7a7a7a;
$color-silver: silver;
$color-coal: #23222270;
$color-background-blue: #167495;
$color-hover-blue: #5b809e;
$app-container-background: #f0f3f5;
$color-hover: #dbdfe3;

// Gradients on buttons
$enable-gradients: true;

// Shadows on buttons / modals
$enable-shadows: false;

//button input margin top
$button-top: 31px;

// main button width
$button-100: 100px;

// table column full width
$table-full-width: 100%;

// Slightly modifies the xl breakpoint by changing the max-width from 1140 to 1160 (to cater for larger padding).
// Adds an xxl breakpoint for full HD screens.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1160px,
  xxl: 1559px,
);

// override bootstrap 4 variables
$bs-primary: #9c27b0;
$bs-secondary: #58595b;
$bs-success: #4daf50;
$bs-danger: #D90000;
$bs-warning: #ffc107;
$bs-info: #00B0F0;
$bs-light: #ECF0F4;
$bs-dark: #343a40;

//custom colors
$adminDarkGrey:	#dddddd;
$textgrey: #bcbcbc;
$saveButtonMaroon: #cb5c2d;
$saveButtonMaroonHover: #ad5028;
$saveButtonMaroonHoverBorder: #823c1d;
$globalGreen: #4daf50;
$globalGreenHover: #459648;
$globalGreenHoverBorder:#3a7a3c;
$trashGrey: #b8b8b8;
$trashGreyHover: #9e9e9e;
$trashGreyHoverBorder:#858585;
$grayBorderColor:#ebedf0;
$grayIcons: #ababab;
$grayTabBorders:#d6d6d6;