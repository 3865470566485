.item{
  border: none;
}

.dndOverFlow {
  overflow-x: scroll;
}

.green:hover{
  background-color: #a4f8a6;
  border-radius: 25px;
}

.yellow:hover{
  background-color: #fdff74;
  border-radius: 25px;
}

.red:hover{
  background-color: #ff6666;
  border-radius: 25px;
}