.btn {
  color: #fff !important;
  border: none !important;
  cursor: pointer !important;
  margin: 2px;
  padding: 10px 20px !important;
  position: relative !important;
  font-size: 12px !important;
  min-width: auto !important;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 14%), 0 3px 1px -2px rgba(153, 153, 153, 20%),
    0 1px 5px 0 rgba(153, 153, 153, 12%) !important;
  min-height: auto !important;
  text-align: center !important;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
  font-weight: 400 !important;
  line-height: 1.42857143 !important;
  white-space: nowrap !important;
  will-change: box-shadow, transform !important;
  touch-action: manipulation !important;
  border-radius: 3px !important;
  letter-spacing: 0 !important;
  vertical-align: middle !important;
  background-color: #999 !important;
}

@media only screen and (max-width: 1100px) {
  .btn {
    color: #fff !important;
    border: none !important;
    cursor: pointer !important;
    margin: 2px;
    padding: 10px 20px !important;
    position: relative !important;
    font-size: 12px !important;
    min-width: auto !important;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 14%), 0 3px 1px -2px rgba(153, 153, 153, 20%),
      0 1px 5px 0 rgba(153, 153, 153, 12%) !important;
    min-height: auto !important;
    text-align: center !important;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
    font-weight: 400 !important;
    line-height: 1.42857143 !important;
    white-space: nowrap !important;
    will-change: box-shadow, transform !important;
    touch-action: manipulation !important;
    border-radius: 3px !important;
    letter-spacing: 0 !important;
    vertical-align: middle !important;
    background-color: #999 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .btn {
    color: #fff !important;
    border: none !important;
    cursor: pointer !important;
    margin: 2px;
    padding: 10px 20px !important;
    position: relative !important;
    font-size: 12px !important;
    min-width: auto !important;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 14%), 0 3px 1px -2px rgba(153, 153, 153, 20%),
      0 1px 5px 0 rgba(153, 153, 153, 12%) !important;
    min-height: auto !important;
    text-align: center !important;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
    font-weight: 400 !important;
    line-height: 1.42857143 !important;
    white-space: nowrap !important;
    will-change: box-shadow, transform !important;
    touch-action: manipulation !important;
    border-radius: 3px !important;
    letter-spacing: 0 !important;
    vertical-align: middle !important;
    background-color: #999 !important;
  }
}

.btn-default {
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 14%), 0 3px 1px -2px rgba(153, 153, 153, 20%),
    0 1px 5px 0 rgba(153, 153, 153, 12%) !important;
  background-color: #999 !important;
}

.btn-primary:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #007bff !important;
}

.btn-outline-primary {
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 14%), 0 3px 1px -2px rgba(156, 39, 176, 20%),
    0 1px 5px 0 rgba(156, 39, 176, 12%) !important;
  background-color: #9c27b0 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #9c27b0 !important;
}

.btn-outline-info {
  box-shadow: 0 2px 2px 0 rgba(0, 172, 193, 14%), 0 3px 1px -2px rgba(0, 172, 193, 20%),
    0 1px 5px 0 rgba(0, 172, 193, 12%) !important;
  background-color: #00acc1 !important;
}

.btn-outline-info:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #00acc1 !important;
}

.btn-info:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #00acc1 !important;
}
.btn-outline-danger {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%);
  background-color: #f44336 !important;
}

.btn-outline-danger:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #f44336 !important;
}

.btn-danger:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #f44336 !important;
}

.btn-outline-warning {
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 14%), 0 3px 1px -2px rgba(255, 152, 0, 20%),
    0 1px 5px 0 rgba(255, 152, 0, 12%) !important;
  background-color: #ff9800 !important;
}

.btn-outline-warning:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #ff9800 !important;
}
.btn-warning:hover,
.btn-warning:focus {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #ff9800 !important;
}
.btn-outline-success {
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 14%), 0 3px 1px -2px rgba(76, 175, 80, 20%),
    0 1px 5px 0 rgba(76, 175, 80, 12%) !important;
  background-color: #4caf50 !important;
}

.btn-outline-success:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #4caf50 !important;
}

.btn-success:hover {
  color: #fff !important;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #4caf50 !important;
}
.btn-light {
  color: black !important;
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
}

.btn-light:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 42%), 0 4px 23px 0px rgba(0, 0, 0, 12%),
    0 8px 10px -5px rgba(153, 153, 153, 20%) !important;
  background-color: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
}

.btn-outline-dark {
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 14%), 0 3px 1px -2px rgba(76, 175, 80, 20%),
    0 1px 5px 0 rgba(76, 175, 80, 12%) !important;
  background-color: white !important;
}

.btn-link {
  margin-bottom: 4px !important;
  padding: 6px 6px !important;
  background-color: white !important;
  color: #47994a !important;
  box-shadow: none !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px !important;
  font-weight: 600;
}

button.report-action-button.btn.btn-secondary {
  background: #47994a !important;
}
