@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

.boldTitle span {
    font-weight: bold;
}

.sentiment > * > div.neo-form-group-floating label.neo-floating-label {
     position:  static !important; 
}

.moderationStatesContainer {
    border-style: solid ;
    border-color: #e7e7e7;
    border-width: 0.5px;
}

.moderation-notes {
    border-color: #145B92 !important;
    background-color: #145b9218 !important;
}

.modal .modal-body .loader-container {
    max-height: 500px;
}