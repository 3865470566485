@import "../../Styles/Variables.scss";

$header-height: 54px;

.sidebar {
    .sidebar-header {
        .Logo {
            img{
 
                width: 100%;
                height: 100%;
            }
        }
    }
}

.sidebar {
    background: #0e0e0eeb;
    height: 93.9vh;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-flow: column nowrap;

    .nav-link {
        color: white;
    }

    img {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inherit;
        margin-left: 3%;
        transition: width 1s;
        visibility: visible;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .sidebar-header {
        flex: 0 0 auto;
        text-align: center;
        visibility: visible;
        opacity: 1;
        transition: ease-in-out visibility 1s linear 1s, opacity 1s;
        background-color: white;
    }

    .sidebar-content {
        overflow-y: scroll;
        flex: 1;
        ul {
            padding: 0;
            margin: 0;
            transition: max-height ease-in-out 1s;

            li {
                list-style-type: none;
                position: relative;
                div.menu-expander {
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    i.fa.fa-caret-right {
                        background: none;
                        width: 4px;
                        height: 4px;
                    }
                    i.fa.fa-caret-down {
                        background: none;
                        width: 4px;
                        height: 4px;
                    }
                    button {
                        text-rendering: auto;
                        color: unset;
                        letter-spacing: unset;
                        word-spacing: unset;
                        text-transform: unset;
                        text-indent: 0px;
                        text-shadow: none;
                        display: unset;
                        text-align: unset;
                        align-items: unset;
                        cursor: unset;
                        background-color: unset;
                        box-sizing: unset;
                        margin: unset;
                        font: unset;
                        padding: unset;
                        border-width: unset;
                        border-style: unset;
                        border-color: unset;
                        border-image: unset;
                        background: transparent;

                        &:focus,
                        &:hover,
                        &:visited,
                        &:active {
                            outline: unset !important;
                            outline: unset !important;
                        }
                    }
                }
                div.mini-menu-expander {
                    height: 0px;
                    i.fa.fa-caret-right {
                        background: none;
                        position: relative;
                        left: 140%;
                        bottom: 49px;
                        color: $white;
                        display: none;
                    }
                    i.fa.fa-caret-down {
                        background: none;
                        position: relative;
                        left: 51.5%;
                        bottom: 28.2px;
                        color: $white;
                    }
                    button {
                        text-rendering: auto;
                        color: unset;
                        letter-spacing: unset;
                        word-spacing: unset;
                        text-transform: unset;
                        text-indent: 0px;
                        text-shadow: none;
                        display: unset;
                        text-align: unset;
                        align-items: unset;
                        cursor: unset;
                        background-color: unset;
                        box-sizing: unset;
                        margin: unset;
                        font: unset;
                        padding: unset;
                        border-width: unset;
                        border-style: unset;
                        border-color: unset;
                        border-image: unset;
                        background: transparent;

                        &:focus,
                        &:hover,
                        &:visited,
                        &:active {
                            outline: unset !important;
                            outline: unset !important;
                        }
                    }
                }

                @media (min-width: 769px) and (max-width: 1360px) {
                    div.mini-menu-expander {
                        position: absolute;
                        i.fa.fa-caret-right {
                            background: none;
                            position: relative;
                            left: 79%;
                            bottom: unset;
                            color: $white;
                        }
                        i.fa.fa-caret-down {
                            background: none;
                            position: relative;
                            left: 79%;
                            bottom: unset;
                            color: $white;
                        }
                        button {
                            text-rendering: auto;
                            color: unset;
                            letter-spacing: unset;
                            word-spacing: unset;
                            text-transform: unset;
                            text-indent: 0px;
                            text-shadow: none;
                            display: unset;
                            text-align: unset;
                            align-items: unset;
                            cursor: unset;
                            background-color: unset;
                            box-sizing: unset;
                            margin: unset;
                            font: unset;
                            padding: unset;
                            border-width: unset;
                            border-style: unset;
                            border-color: unset;
                            border-image: unset;
                            background: transparent;

                            &:focus,
                            &:hover,
                            &:visited,
                            &:active {
                                outline: unset !important;
                                outline: unset !important;
                            }
                        }
                    }

                    div.menu-expander {
                        height: 0px;
                        position: unset;
                        right: unset;
                        top: unset;
                        i.fa.fa-caret-right {
                            background: none;
                            position: relative;
                            left: 321%;
                            bottom: 51px;
                            color: $white;
                            width: 4px;
                            height: 4px;
                        }
                        i.fa.fa-caret-down {
                            background: none;
                            position: relative;
                            left: 169%;
                            bottom: 28.5px;
                            color: $white;
                            width: 4px;
                            height: 4px;
                        }
                        button {
                            text-rendering: auto;
                            color: unset;
                            letter-spacing: unset;
                            word-spacing: unset;
                            text-transform: unset;
                            text-indent: 0px;
                            text-shadow: none;
                            display: unset;
                            text-align: unset;
                            align-items: unset;
                            cursor: unset;
                            background-color: unset;
                            box-sizing: unset;
                            margin: unset;
                            font: unset;
                            padding: unset;
                            border-width: unset;
                            border-style: unset;
                            border-color: unset;
                            border-image: unset;
                            background: transparent;

                            &:focus,
                            &:hover,
                            &:visited,
                            &:active {
                                outline: unset !important;
                                outline: unset !important;
                            }
                        }
                    }
                }
            }

            .menu-item {
                max-height: 60px;
                min-height: 60px;
                overflow: hidden;
                
                
                &.has-children {
                    cursor: pointer;
                }

                &:hover {
                    a,
                    span {
                        background-color: $globalGreenHover;
                        color: $white;
                        max-height: 60px;
                        min-height: 60px;
                    }
                }
            }

            .menu-item {
                .fa {
                    color: $white;
                    width: 36px;
                    height: 36px;
                    vertical-align: center;
                    padding-top: 12px;
                    margin-right: 3px;
                }

                a.active {
                    background-color: lighten($globalGreenHover, 1%);
                    color: $white;
                    max-height: 60px;
                    min-height: 60px;
                }
                a,
                span {
                    color: $color-text;
                    display: block;
                    padding: 10px;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        ul.menu-level-2 {
            background: #8989897d;
            .menu-item {
                a.active {
                    height: 53px;
                }
            }
            a,
            span {
                font-size: 0.9em;
                padding: 10px 0px 10px 18px;
                height: 53px;
                display: flex !important;
            }
            .menu-item i {
                height: 30px;
                width: 30px;
                padding-top: 7.8px;
                font-size: 13px;
                margin-left:3%
            }
            div.mini-menu-expander {
                height: 0px;
                i.fa.fa-caret-right {
                    background: none;
                    position: relative;
                    left: 335.5%;
                    bottom: 43px;
                    color: $white;
                    width: 4px;
                    height: 4px;
                }
                i.fa.fa-caret-down {
                    background: none;
                    position: relative;
                    left: 198.5%;
                    bottom: 27px;
                    color: $white;
                    width: 4px;
                    height: 4px;
                }
            }
        }

        ul.menu-level-3 {
            background: $lightGrey;
            text-indent: 4px;
            .menu-item {
                a.active {
                    height: 53px;
                }
            }
            a,
            span {
                font-size: 0.9em;
                height: 53px;
                padding: 10px 0px 10px 13px;
                display: flex !important;
            }
            .menu-item i {
                background: $white;
                height: 30px;
                width: 30px;
                padding-top: 8px;
                font-size: 13px;
                padding-right: 5px;
                .fa {
                    font-size: 15px;
                }
            }
        }
    }
}

.menu-item{
    min-height: 63px;
    max-height: 63px;
}

//important tags used for overwriting menu levels 1, 2 and 3
@media (min-width: 769px) and (max-width: 1360px) {
    .sidebar {
        box-shadow: none;
        height: 94vh;
        img {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .sidebar-header {
            visibility: visible;
            opacity: 1;
            transition: ease-in-out visibility 1s linear 1s, opacity 1s;
        }
    }
    ul.menu-level-2 {
        background: $lightGrey;
        .menu-item {
            a.active {
                height: 53px;
            }
        }
        a,
        span {
            font-size: 0.9em;
            padding: 10px 0px 10px 18px;
            height: 53px;
        }
        div.mini-menu-expander {
            height: 0px;
            i.fa.fa-caret-right {
                background: none;
                position: relative;
                left: 72% !important;
                bottom: 0px !important;
                color: $globalGreen !important;
                width: 4px;
                height: 4px;
            }
            i.fa.fa-caret-down {
                background: none;
                position: relative;
                left: 72% !important;
                bottom: 0px !important;
                color: $globalGreen !important;
                width: 4px;
                height: 4px;
            }
        }
    }
}
@media (min-width: 1561px) and (max-width: 2000px) {
    .sidebar {
        height: 94.9vh;
    }
}
@media (min-width: 345px) and (max-width: 800px) {
    .sidebar {
        height: 91.2vh;
    }
}
//important tags used for overwriting menu levels 1, 2 and 3

.responsive-hidden {
    display: contents !important;
}

