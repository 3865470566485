.selected-item-success {
  background-color: #c7ffd1;
}

.selected-item-danger {
  background-color: #ffc7c7;
}

/// Start Custom tables
td {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-color: rgb(199, 199, 199) !important;
}

table {
  border: none !important;
}

/// End Custom tables

/// Extra card css

.dnd-progress {
  color: #3c4858;
  margin-top: 15px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
  text-decoration: none;
  margin-bottom: 15px;
}

@media (max-width: 1111px) {
  .dnd-progress {
    color: #3c4858;
    margin-top: 15px;
    min-height: auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    text-decoration: none;
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.Card-icon-header-text-dnd {
  color: #3c4858;
  margin-top: 15px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 0px;
  text-decoration: none;
  margin-bottom: 15px;
}

@media (max-width: 1111px) {
  .Card-icon-header-text-dnd {
    color: #3c4858;
    margin-top: 15px;
    min-height: auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    text-decoration: none;
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.Card-icon-header-text {
  color: #3c4858;
  margin-top: 15px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 0px;
  text-decoration: none;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .Card-icon-header-text {
    color: #3c4858;
    margin-top: 45px;
    min-height: auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0px;
    text-decoration: none;
    margin-bottom: 15px;
  }
}

.Card-icon-header-text-small {
  color: #3c4858;
  margin-top: 45px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 0px;
  text-decoration: none;
  margin-bottom: 5px;
}
.Card-icon-header-button {
  color: #3c4858;
  margin-top: 15px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 0px;
  text-decoration: none;
  margin-bottom: 15px;
  float: right;
}

th {
  padding: 5px 5px !important;
  position: relative !important;
  font-size: 1.3rem !important;
  // font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.42857143 !important;
  border-bottom: none !important;
  // vertical-align: middle !important;
  background-color: white !important;
  border: none !important;
  color: #4daf50 !important;
}

td {
  padding: 5px !important;
  vertical-align: middle !important;
}

.module-tiles {
  color: #3c4858;
  margin-top: 0px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
}

table {
  margin-top: 1%;
}

.module-description {
  color: #999;
  margin: 0;
  font-size: 14px;
  margin-top: 0;
  padding-top: 5px;
  margin-bottom: 0;
}

/// Custom Image Card css

.card-header-hover:hover {
  transform: translate3d(0, -50px, 0);
}

.card-header-hover {
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.card-hover-under {
  position: absolute;
  z-index: 1;
  top: -50px;
  width: calc(100% - 30px);
  left: 17px;
  right: 17px;
  text-align: center;
}

.under-chart-icons {
  width: 17px;
  height: 17px;
}

.image-body-card {
  min-width: fit-content !important;
}

.card-header-hover img {
  min-width: 250px;
  min-height: 250px;
  max-height: 250px;
  max-width: 250px;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-top: 5%;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.unchecked-Icon {
  width: 13px !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 13px !important;
  padding: 9px;
  border-radius: 3px;
}

.question-circle {
  color: white !important;
}

.checked-Icon {
  width: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 20px !important;
  border-radius: 3px;
}

.check-root {
  padding: 14px !important;
}

.check-root:hover {
  background-color: $globalGreen;
}

.checked {
  color: $globalGreen !important;
}

.checkbox-label {
  font-size: 0.875rem !important;
  word-wrap: break-word !important;
}

.fa-comments {
  color: #fff;
}

.Card-Title {
  color: black !important;
}

.card-left {
  float: left !important;
}

.card-right {
  float: right !important;
}

.card-left,
.card-right {
  display: inline;
}

.card-category {
  color: #999;
  font-size: 14px;
  padding-top: 10px;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
}

.card-category-cptd {
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) and (min-width: 1160px) {
  .card-category {
    color: #999;
    font-size: 13px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1160px) {
  .card-category {
    color: #999;
    font-size: 12px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1120px) {
  .card-category {
    color: #999;
    font-size: 11px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1070px) {
  .card-category {
    color: #999;
    font-size: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0;
  }
}

.info-card-Title {
  color: #3c4858;
  margin-top: 0px;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
}

.page-subcategories-title {
  color: "#3C4858";
  text-decoration: "none";
  text-align: "center";
}

.badge-Message {
  border: none !important;
  color: #3c4858 !important;
  background-color: none !important;
  text-align: center !important;
}

.tool-tip {
  z-index: 5000 !important;
}

.card-award-description {
  flex: 1 1 auto;
  padding: 0.9375rem 20px;
  position: relative;
  -webkit-box-flex: 1;
}

.card-award-title {
  color: #3c4858;
  margin-top: 0;
  min-height: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
}

.card-award-type {
  color: #999999;
  margin-top: 10px;
}

.badge-Award-Modal .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.badge-Award-Modal .modal-content .modal-header {
  display: none !important;
}

.badge-Award-Modal .modal-content .modal-footer {
  display: none !important;
}

.testimonial-Badge {
  border: 3px solid transparent !important;
  border-radius: 25px;
  //@at-root border-image: linear-gradient(to bottom right, #9128c2 0%, #2c90fc 25%, #8fc427 50%, #e2b436 75%, #fd5918 100%), radial-gradient(circle at top left, #f00,#3020ff)  !important;
  // border-image: linear-gradient(to bottom right, #9128c2 0%, #2c90fc 25%, #8fc427 50%, #e2b436 75%, #fd5918 100%) !important;
  border-image: linear-gradient(
    to bottom right,
    #9128c2 0%,
    #2c90fc 25%,
    #8fc427 50%,
    #e2b436 75%,
    #fd5918 100%
  ) !important;
  border-image-slice: 1 !important;
}

.zoom {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.zoom:hover {
  transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
}

.react-select-bs__menu {
  z-index: 5000 !important;
}

.image-card-learning-object {
  max-height: "110px";
  max-width: "110px";
  text-align: "center";
}

.media-image {
  height: 110px;
  width: 110px;
  max-height: 110px;
  max-width: 110px;
}

.badge-square-image {
  border-radius: 6px !important;
  box-shadow: none !important;
  width: 150px !important;
  height: 150px !important;
  max-height: 150px !important;
  max-width: 150px !important;
  display: inline-block;
}

.badge-square-image-view {
  display: inline-block;
}

.delete-badge-btn {
  display: flex;
  justify-content: center;
}

.badge-img {
  max-width: 200px;
  max-height: 200px;
  display: inline-block;
}

.badge-square-modal {
  border-radius: 6px !important;
}

.invalid-column {
  color: red !important;
}

.floating-menu-conversations {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 50;
}

.notification-btn {
  float: right;
  margin-top: 4% !important;
  margin-right: 4% !important;
}

.link-help-btn {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  float: right;
}

.fa-question-circle {
  background-color: #fff;
  border-radius: 100%;
}
