.DraftEditor-editorContainer > .public-DraftEditor-content {
  background-color: white;
}

span {
  margin-left: 2px;
}

.rdw-editor-main {
  background-color: white !important;
  margin-bottom: 5px !important;
  border: solid 0.1px #ced4da !important;
  font-size: 14px !important;
  font-family: Verdana !important;
  font-weight: 300 !important;
  color: black !important;
}

.rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-cloud-upload-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}

.custom-editor-class {
  border: none !important;
  margin-top: -10px !important;
  margin: 0px !important;
  overflow: initial !important;
}
