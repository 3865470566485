@import "../../App/Styles/Variables.scss";

.col-hierarcy {
    .table{
        border: none  !important;
    }
    thead{
        display: none !important;
    }
}

.Neo-Card-Success {
    .neo-card.card .card-header {
        background: #4fb252;
        border: none;
        margin-bottom: 0;
        color: #fff;
    }
}

.fa-border {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    color: white;
    background-color: white;
    margin-right: 5px;
    margin-top: 1%;
}

// .fa-plus {
//     color: $bs-success;
// }

.selected-item-css{
    background-color: #c7ffd1;
}
.selected-item-box{
    background-color: rgb(255, 255, 255);
    border: solid #dee2e6 2px;
}