.toolbar {
  padding-bottom: 20px;
}
.bottom-toolbar {
    padding-top: 20px;
}
.tab-pane {
  padding-top: 18px;
}
.manage-user-group {
  .tab-content {
    padding: 15px 0px 15px 0px;
  }
}

.nofloat {
  float: unset !important;
}


.group-roles {
  width: 100%;
  li {
    list-style-type: none;
    padding-left: 5px;
    border-left: solid;
    border-color: #e2e5e9;
  }
  .role-item {
    width: 100%;
    > button {
      background: none !important;
      border: none;
      min-width: 135px;
      box-shadow: none;
      margin-left: 0px;
      margin-right: 15px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .role-lock {
      color: #d90000 !important;
    }
    .role-unlock {
      color: #109110 !important;
    }
    .role-undefined {
      color: #ffc107 !important;
    }
    .role-item-text {
      cursor: pointer;
      display: inline;
      vertical-align: middle;
    }
    &.no-select {
      .btn {
        cursor: default;
      }
      .role-item-text {
        cursor: default;
      }
    }
    .role-expander {
      margin-right: 10px;
    }
  }
}
.role-tools {
  margin-left: 20px;
}
.modal-overflow {
  overflow-y: scroll;
}