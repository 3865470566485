@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";

// Input override
.form-control {
    border-radius: 1px;
}

.btn-link-inline {
    padding: 0;
    vertical-align: inherit;
}
.btn.form-btn {
    margin-top: 31px;
    width: 100%;
}
.qa-mode {
    background-color: deepskyblue;
}
.uat-mode {
    background-color: red;
}
.site-mode {
    color: white;
}

// Alerts and toasts
@each $color, $value in $theme-colors {
    .toast-header-#{$color} {
        @include alert-variant(
            theme-color-level($color, $alert-bg-level),
            theme-color-level($color, $alert-border-level),
            theme-color-level($color, $alert-color-level)
        );
    }
    .toast-count-down-#{$color} {
        background-color: theme-color-level($color, -3);
    }
}

.alert-warning {
    @include alert-variant(
        theme-color-level("warning", -9),
        theme-color-level("warning", -6),
        theme-color-level("warning", 7)
    );
}
.alert-dark {
    @include alert-variant(theme-color-level("dark", -3), theme-color-level("dark", -1), $bs-light);
}
.toast-header-warning {
    @include alert-variant(
        theme-color-level("warning", -7),
        theme-color-level("warning", -4),
        theme-color-level("warning", $alert-color-level)
    );
}
.toast-header-dark {
    @include alert-variant(
        theme-color-level("dark", -8),
        theme-color-level("dark", -4),
        theme-color-level("dark", $alert-color-level)
    );
}

/* Card overrides */
.neo-card.card {
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 0px;
    border-color: lighten($form-border, 3%);

    .card-header {
        background: lighten($form-widget-color, 2%);
        padding: 10px 20px;
        border-bottom-color: lighten($form-border, 3%);
        margin-bottom: 0;

        i {
            margin-right: 10px;
        }
    }
    .card-body {
        padding: 20px;
    }
}

@media (max-width: 1360.98px) {
    .neo-card.card {
        border: none;

        .card-header {
            background: none;
            padding: 10px 0px;
            font-weight: 600;
        }
        .card-body {
            padding: 15px 0;
        }
    }
}

.neo-file-drop {
    height: 70px !important;
    text-align: center !important;
    color: $globalGreen !important;
    border-color: $globalGreen !important;
    margin-bottom: 10px !important;
}

.neo-file-drop .btn-primary {
    float: none !important;
}
