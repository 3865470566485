@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

.OrgMaintenance{
    padding-top: 20px;
}

.OrgMaintenance .nav-link {
    color: $coal !important;
}

.OrgMaintenance .nav-item {
    border-style: solid;
    border-color: $grayTabBorders;
    border-width: thin;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $globalGreen;
}

.OrgMaintenance .nav-item.active {
    border-style: solid;
    border-color: $grayTabBorders;
    border-width: thin;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $grayTabBorders;
}

.saveButton {
    margin-right: 8px;
}

.neo-datepicker-content th {  
    font-size: 14px !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }