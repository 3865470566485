.list {
    width: 250;
}
.fullList {
    width: "auto";
}

.fa-times-circle {
    color: grey;
    float: right !important;
}

.fa-times-circle:hover {
    color: #000000;
    cursor: pointer;
}

.fa-long-arrow-alt-left {
    color: #212529;
    float: right !important;
}

.fa-long-arrow-alt-left:hover {
    color: #000000;
    cursor: pointer;
}

.fa-window-maximize {
    color: grey;
    margin: 10px;
}

.fa-window-maximize:hover {
    color: #000000;
    cursor: pointer;
}

.MuiPaper-root {
    transition: 2s;
}

.forumItem:hover {
    text-decoration: underline;
    cursor: pointer;
}

.MuiDrawer-paperAnchorBottom {
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.Drawer-Maximised {
    max-height: 95vh !important;
    min-height: 95vh !important;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.Drawer-Minimised {
    max-height: 50vh !important;
    min-height: 50vh !important;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.MessageHolder{
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
}

.PostUserIcon{
    text-align: center;
   
}

.MuiAvatar-colorDefault {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    margin-top: 5%;
}

.iconSection{
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
}

.postControls {
    cursor: pointer;
}

.user-chat-icon{
    height: 40px;
    width: 40px;
    border: solid 0.1px;
    border-radius: 50%;
    text-align: center;
}

.user-chat-icon p {
    font-weight: 300;
    color: white;
    margin: 22%;
}

.reportPost {
    float: right;
}

.chat-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
}

.editor-title{
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    font-weight: bold;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 15px;
}

.custom-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: top;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    margin-left: 10px;
}

.custom-avatar span {
    color:#fff;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
}

.report-btn {
    color: #f44336;
}

.report-btn:hover{
    cursor: pointer;
    color: #d61f12;
}

.reply-btn{
color: #00acc1
}

.reply-btn:hover{
    cursor: pointer;
    color: #018c9e;
}

.modal-body{
    z-index: 2500 !important;
}
.modal{
    z-index: 1500 !important;
}

.reported-text{
    background-color: #ffd9d9;
}

.initial-reported-text{    
    background-color: #ffd9d9;
    margin-left: 10px;
}

.report-flag{
    color: red;
}